@use '@ingka/variables/style.scss' as variables;

$colour-cloudyblue: #ebf2f8;

.header {
    display: flex;
    margin-bottom: variables.$space-75;
}
.listName {
    font-size: variables.$font-size-75;
    font-weight: variables.$font-weight-bold;
    text-decoration: none;
    padding: variables.$space-25 variables.$space-25 variables.$space-25 0;
    margin-right: variables.$space-25;
    &:hover,
    &:active {
        text-decoration: underline;
        cursor: pointer;
    }
}
.listNameContent {
    flex: 1;
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.listItemCount {
    color: variables.$colour-neutral-6;
    font-size: variables.$font-size-50;
}
.headingContainer {
    display: flex;
}
