@use '@ingka/variables/style.scss' as variables;

.landingContainer {
    /* autoprefixer grid: on */
    grid-column: 1 / 6; // Full width
    @include variables.md {
        /* autoprefixer grid: on */
        grid-column: 1 / 7; // Full width
    }
    @include variables.lg {
        /* autoprefixer grid: on */
        grid-column: 1 / 13; // Full width
    }
}
