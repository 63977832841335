@use '@ingka/variables/style.scss' as variables;

.container {
    @media print {
        display: none;
    }
}

.heading {
    display: block;
    margin: variables.$space-125 0;
    text-align: center;
}

.cartIcon {
    margin-right: variables.$space-50;
}

.cartText {
    vertical-align: middle;
}

.modal {
    text-align: left;
    ul {
        list-style: none;
        text-align: left;
    }
}
