@use '@ingka/variables/style.scss' as variables;

.loginpromosmall {
    margin: variables.$space-250 0 variables.$space-250;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: variables.$thickness-thin solid variables.$colour-neutral-3;
    padding: variables.$space-200 variables.$space-125;
    border-radius: variables.$radius-s;

    p {
        margin-bottom: variables.$space-150;
    }
}

.experimentLoginpromosmall {
    @extend .loginpromosmall;
    border: none;
    padding: 0;
}
