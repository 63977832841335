@use '@ingka/variables/style.scss' as variables;

.addItemField {
    margin-bottom: variables.$space-150;
    margin-top: variables.$space-150;
}

.addItemButton {
    margin-bottom: variables.$space-150;
}
