@use '@ingka/variables/style.scss' as variables;
@use '@ingka/focus/_mixins.scss' as focus;

.inspirationCollection {
    margin-top: variables.$space-250;
    margin-bottom: variables.$space-250;
    :focus-visible {
        @include focus.focus();
    }
}

.header {
    margin-bottom: variables.$space-75;
}

.name {
    font-weight: variables.$font-weight-bold;
    text-decoration: none;
    &:hover,
    &:active {
        text-decoration: underline;
        cursor: pointer;
    }
}

.imagesCount {
    font-size: variables.$font-size-50;
}

.images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: variables.$space-25;
    padding: variables.$space-25;
    align-items: center;
    background-color: variables.$colour-neutral-2;
    border-radius: variables.$radius-s;
    @media (min-width: variables.$breakpoint-l) {
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: variables.$space-25;
    }
    &:hover {
        cursor: pointer;
    }
}

.imagesEmpty {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 50%;
    align-items: center;
    text-align: center;
    background-color: variables.$colour-neutral-2;
    border-radius: variables.$radius-s;
    @media (min-width: variables.$breakpoint-l) {
        padding-bottom: 20%;
    }
    &:hover {
        cursor: pointer;
    }
}

.imagesEmptyContent {
    position: absolute;
    left: 0;
    right: 0;
}

.imageWrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;

    &:nth-child(1) .image {
        border-top-left-radius: variables.$radius-s;
        border-bottom-left-radius: variables.$radius-s;
    }

    &:nth-child(3) .image {
        border-top-right-radius: variables.$radius-s;
    }

    &:nth-child(5) .image {
        border-bottom-right-radius: variables.$radius-s;
    }

    @media (min-width: variables.$breakpoint-l) {
        &:nth-child(3) .image {
            border-top-right-radius: 0;
        }

        &:nth-child(5) .image {
            border-top-right-radius: variables.$radius-s;
            border-bottom-right-radius: variables.$radius-s;
        }
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: variables.$colour-neutral-1;
    object-fit: cover;
}

@media (max-width: variables.$breakpoint-l) {
    .withUpToTwoImages {
        grid-template-columns: repeat(2, 1fr);
    }

    .withMoreThanTwoImages {
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: repeat(2, 1fr);

        .imageWrapper:nth-child(1) {
            padding-top: 102%;
            grid-row: 1 / 3;
            grid-column: 1;
        }

        .imageWrapper:nth-child(2) {
            grid-row: 1;
            grid-column: 2;
        }

        .imageWrapper:nth-child(3) {
            grid-row: 1;
            grid-column: 3;
        }

        .imageWrapper:nth-child(4) {
            grid-row: 2;
            grid-column: 2;
        }

        .imageWrapper:nth-child(5) {
            grid-row: 2;
            grid-column: 3;
        }
    }
}
