@use '@ingka/variables/style.scss' as variables;

.container {
    margin-top: variables.$space-250;
    margin-bottom: variables.$space-250;
    transition: opacity variables.$duration-slow-xl-screen,
        max-height variables.$duration-slow-xl-screen,
        padding variables.$duration-slow-xl-screen,
        margin-top variables.$duration-slow-xl-screen;
}
.hidden {
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin-top: -#{variables.$space-250};
}
