@use '@ingka/variables/style.scss' as variables;

// Variables that are not (yet) in the design system
$radius-xs: 2px;
$thickness-thicker: 3px;

.container {
    // Aspect ratio hack
    position: relative;
    padding-bottom: 50%;
    height: 0;
    // End of Aspect ratio hack
}
.content {
    // Aspect ratio hack
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // End of Aspect ratio hack
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $thickness-thicker;
    border: $thickness-thicker variables.$colour-neutral-2 solid;
    background: variables.$colour-neutral-2;
    margin-bottom: variables.$space-100;
    border-radius: variables.$radius-s;
    overflow: hidden;
}
.cursorPointer {
    cursor: pointer;
}
.image {
    height: auto;
    width: 100%;
    background-color: variables.$colour-neutral-1;
}
// 1 cell into 4 images or placeholders
.container4 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: $thickness-thicker;
    grid-row-gap: $thickness-thicker;
    background: variables.$colour-neutral-2;
}

.borderLeftRadius {
    border-top-left-radius: $radius-xs;
    border-bottom-left-radius: $radius-xs;
}
.borderRightRadius {
    border-top-right-radius: $radius-xs;
    border-bottom-right-radius: $radius-xs;
}
.borderTopRightRadius {
    border-top-right-radius: $radius-xs;
}
.borderBottomRightRadius {
    border-bottom-right-radius: $radius-xs;
}
.noItems {
    grid-template-columns: 1fr;
    text-decoration: none;
    align-items: center;
    text-align: center;
}
