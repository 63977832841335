@use '@ingka/variables/style.scss' as variables;
@use '@ingka/focus/_mixins.scss' as focus;

// Variables that are not (yet) in the design system
$thickness-thicker: 3px;

.container {
    // Aspect ratio hack
    position: relative;
    padding-bottom: 20%;
    height: 0;
    // End of Aspect ratio hack

    :focus-visible {
        @include focus.focus();
    }
}
.content {
    // Aspect ratio hack
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // End of Aspect ratio hack
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: $thickness-thicker;
    border: $thickness-thicker variables.$colour-neutral-2 solid;
    background: variables.$colour-neutral-2;
    margin-bottom: variables.$space-100;
    border-radius: variables.$radius-s;
    overflow: hidden;
}
.cursorPointer {
    cursor: pointer;
}
.image {
    height: auto;
    width: 100%;
    background-color: variables.$colour-neutral-1;
}
.noItems {
    grid-template-columns: 1fr;
    text-decoration: none;
    align-items: center;
    text-align: center;
}
