@use '@ingka/variables/style.scss' as variables;

.container {
    margin: variables.$space-250 0 variables.$space-250 0;
    padding: variables.$space-150;
    background: variables.$colour-commercial-message-ikea-family;
    display: flex;
    flex-direction: column;
    @media (min-width: variables.$breakpoint-m) {
        padding: variables.$space-300;
    }
    h2 {
        margin-bottom: variables.$space-100;
        color: variables.$colour-text-and-icon-5;
    }
}

.list {
    margin-bottom: variables.$space-150;
    padding-left: variables.$space-100;
    color: variables.$colour-text-and-icon-5;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: variables.$space-100;
    @media (min-width: variables.$breakpoint-m) {
        flex-direction: row;
    }
}
