@use '@ingka/variables/style.scss' as variables;

.form {
    display: flex;
}

.inputField {
    flex: 1;
}

.formField {
    width: 100%;
    @media (min-width: variables.$breakpoint-m) {
        width: auto;
    }
}

.buttonContainer {
    white-space: nowrap;
}
