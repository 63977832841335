@use '@ingka/variables/style.scss' as variables;

.recommendations {
    margin: variables.$space-250 0;
    padding-top: variables.$space-250;
    /* autoprefixer grid: on */
    grid-column: 1 / 6; // Full width
    // Setting min-width in-order to make recommendation panel compatible for mobile devices across browsers
    min-width: variables.px2rem(300);
    @include variables.md {
        /* autoprefixer grid: on */
        grid-column: 1 / 7; // Full width
    }
    @include variables.lg {
        /* autoprefixer grid: on */
        grid-column: 1 / 13; // Full width
    }
}

.recommendationsExperiment > * {
    margin-bottom: variables.$space-150;
}
