@use '@ingka/variables/style.scss' as variables;

.title {
    margin-top: variables.$space-250;
    margin-bottom: variables.$space-100;
}
.body {
    margin-bottom: variables.$space-250;
    display: flex;
    gap: variables.$space-250;
    flex-direction: column;
}
